import React from "react"
import { Link,graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-awesome-styled-grid"

import PortfolioNavigationMenu from "../components/portfolioNav"
import PostCard from "../components/card"
import Layout from "../components/layout"
import {MariaPrimary, MariaSecondary} from "../utils/colors"
import SEO from "../components/seo"

class CodingPage extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = "Portfolio | Coding"
        const allCoding = data.allMdx.edges
            
        return (
          <Layout location={this.props.location} title={siteTitle}>
            <SEO title="Portfolio | Coding" />
            <Container style={{margin: "0 0 3rem 0"}}>
            <PortfolioNavigationMenu />
            <Row justify='space-around'>
              {allCoding.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                const tag = node.frontmatter.tags

                return (
                  <Col xs={6} sm={4} md={2.65} align='justify' style={{display: `flex`, flexDirection: `column`, textAlign: `center`}}>
                    <Link
                      style={{ boxShadow: `none`, color: MariaPrimary }}
                      to={`portfolio${node.fields.slug}`}
                    >
                      <PostCard>                  
                        <div key={node.fields.slug} className="card-children" style={{height: `100%`, position: `relative`, overflow: `hidden`}}>
                        
                          <Img style={{height: `100%`, width: `100%`, position: `absolute`}} fluid={node.frontmatter.thumbnail.childImageSharp.fluid} />
                          <div style={{position: `relative`, height: `100%`, zIndex: `2`}}>
                          <h4 style={{WebkitTransform: `translate(-50%, 0)`, position: `absolute`, top: `30%`, left: `50%`}}>
                              {title}
                          </h4>
                          <p style={{WebkitTransform: `translate(-50%, 0)`, position: `absolute`, top: `80%`, left: `50%`, fontFamily: `"Press Start 2P"`}}><span style={{color: MariaPrimary, background: MariaSecondary, padding: `0.3rem 0.5rem`, fontSize: `0.45rem`, fontWeight: `bolder`, borderRadius: `5px`}}>{tag}</span></p>
                          </div>                          
                        </div>
                      </PostCard>  
                    </Link>               
                  </Col>
                  
                )
              })}
            </Row>
            </Container>
          </Layout>
        )
      }    
}

export default CodingPage

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allMdx(filter: {frontmatter: {tags: {eq: "coding"}, collection: {eq: "portfolio-post"}}}, sort: {fields: frontmatter___date, order: DESC}) {
    edges {
      node {
        frontmatter {
          collection
          tags
          thumbnail {
            childImageSharp {
              fluid(
                duotone: { highlight: "#AD316C", shadow: "#251863", opacity: 10 }
                traceSVG: {
                  color: "#251863"
                  turnPolicy: TURNPOLICY_MINORITY
                  blackOnWhite: false
                }
                toFormat: PNG
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          title
          date
        }
        fields {
          slug
        }
      }
    }
  }
}
`
